<template>
  <div class="switch-simulator" :class="{ 'switch-simulator--mobile': isMobile }">
    <div class="switch-simulator__title">
      <div>Simulador<span v-if="isMobile">:</span></div>
    </div>
    <div class="switch-simulator__switch">
      <div class="switch-simulator__switch__success" :class="{ active: value }" @click="onClick(true)">
        acierto
      </div>
      <div class="switch-simulator__switch__failure" :class="{ active: !value }" @click="onClick(false)">
        falla
      </div>
    </div>
  </div>
</template>

<script>
import { tipsSimulator } from '@/utils/analytics';
import { mapGetters } from 'vuex';

export default {
  name: 'TipsSwitchSimulator',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
  },
  methods: {
    onClick(isSuccess) {
      this.$emit('input', isSuccess);
      tipsSimulator({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        value: isSuccess ? 'Acierto' : 'Falla',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.switch-simulator {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  font-size: 1rem;

  &__title {
    width: inherit;
    padding: 0.1em 0;
    text-align: center;
    border-radius: 0.375em;
    background-color: #f4f7fa;
    font-family: Roboto-Regular, sans-serif;
    font-size: 0.812em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    color: #132839;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__switch {
    width: inherit;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0.135em 0.1875em;
    border-radius: 8px;
    background-color: #253239;
    text-transform: capitalize;
    font-family: Heebo, sans-serif;
    font-size: 0.875em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: white;
    margin-top: 0.2em;

    & > div {
      cursor: pointer;
      border-radius: 0.375em;
      padding: 0.3em 0 0.2em;
    }
  }

  &--mobile {
    grid-template-columns: 41% 57%;
    column-gap: 0.375em;
    height: 34px;

    & .switch-simulator__title {
      height: 100% !important;
    }

    & .switch-simulator__switch {
      height: 100% !important;
      font-size: 0.75em;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;

      & > div {
        margin: 0.15em;
        border-radius: 0.45em;
      }
    }

    & > div {
      align-self: center;
    }
  }
}

.active {
  font-weight: bold;

  &.switch-simulator__switch__success {
    background-color: #428ee6;
  }

  &.switch-simulator__switch__failure {
    background-color: #e03c17;
  }
}
</style>
